import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyReporteFactura: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Reporte de facturación
            </h2>
            <p>
                Las facturas canceladas no se contabilizan en el total de la APP, las facturas de reembolso se contabilizan como negativos.
            </p>
            <ImageFooted explanation={`Podrás obtener y configurar los reportes de tu facturación escogiendo los campos deseados y fechas. Puedes descargarlo como CSV para abirlo en Excel o visualizarlo en tu app`}>
                <IMG_Shopify file={'onBillsReport'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/cancelar-facturas`}/>
        </div>
    </Layout>
)

export default ShopifyReporteFactura;